import revive_payload_client_nwelOKtSiS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__ls5nc26isjb7k42si56gieydca/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ak8KnBvBZH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__ls5nc26isjb7k42si56gieydca/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Mih9O9Oize from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__ls5nc26isjb7k42si56gieydca/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fO70Odrpf6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.1_vite@5.4.2_@types+node@22.0.0_sass@1.77._rhrgdp2aufyj2rwtvblib3fg54/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_FD11BoFIMB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__ls5nc26isjb7k42si56gieydca/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5NLLUHLV9L from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__ls5nc26isjb7k42si56gieydca/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_X2dE0QqvfB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__ls5nc26isjb7k42si56gieydca/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_t1gKGBsgDY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__ls5nc26isjb7k42si56gieydca/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_Y255KzEXTo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__ls5nc26isjb7k42si56gieydca/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_tmd8HbWK0P from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-gtag@2.1.0_magicast@0.3.4_rollup@4.19.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_ACpYLWiawk from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.26.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.25.1_@opentelemetry+api@1._2hwbbh7rcuqo2wbzdjy7ouznqa/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_client_7misEjCVBY from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.19.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import slideovers_upxHbWb59I from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.4_rollup@4.19.1_vite@5.4.2_@types+node@22.0.0_sass@1.77.8_terser_v73bplw32dhhzzg66ecpu4wyhq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_WgfqIcp6LN from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.4_rollup@4.19.1_vite@5.4.2_@types+node@22.0.0_sass@1.77.8_terser_v73bplw32dhhzzg66ecpu4wyhq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_XUiAkxtt96 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.4_rollup@4.19.1_vite@5.4.2_@types+node@22.0.0_sass@1.77.8_terser_v73bplw32dhhzzg66ecpu4wyhq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_FPNXa8Yg0O from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.19.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_3lKNfPqomw from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.4.10_magicast@0.3.4_rollup@4.19.1_vite@5.4.2_@types+node@22.0.0_sass@1.77.8_ters_g5mayfmjvdio2dps5vzmrhwoqm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import documentDriven_U5qVpOWMXc from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+content@2.13.2_ioredis@5.4.1_magicast@0.3.4_nuxt@3.13.0_@parcel+watcher@2.4.1_@types+no_j7bie6yhcgpv2setxifex4uswi/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import plugin_auto_pageviews_client_od0mYGLRLI from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.19.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_nwelOKtSiS,
  unhead_ak8KnBvBZH,
  router_Mih9O9Oize,
  _0_siteConfig_fO70Odrpf6,
  payload_client_FD11BoFIMB,
  navigation_repaint_client_5NLLUHLV9L,
  check_outdated_build_client_X2dE0QqvfB,
  chunk_reload_client_t1gKGBsgDY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Y255KzEXTo,
  plugin_client_tmd8HbWK0P,
  sentry_client_ACpYLWiawk,
  sentry_client_config_o34nk2sJbg,
  plugin_client_7misEjCVBY,
  slideovers_upxHbWb59I,
  modals_WgfqIcp6LN,
  colors_XUiAkxtt96,
  plugin_client_FPNXa8Yg0O,
  plugin_3lKNfPqomw,
  documentDriven_U5qVpOWMXc,
  plugin_auto_pageviews_client_od0mYGLRLI
]