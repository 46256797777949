<template>
  <svg width="310" height="48" viewBox="0 0 310 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 46.5455L7.72727 0H20.3636L17.3636 18.1818H34.0909L37.0909 0H49.7273L42 46.5455H29.3636L32.3636 28.3636H15.6364L12.6364 46.5455H0Z" fill="currentColor" />
    <path d="M60.0909 46.5455H46.4545L69.5455 0H86.8182L94.4545 46.5455H80.8182L76.2727 12.8182H75.9091L60.0909 46.5455ZM60.6364 28.1818H86.2727L84.6364 37.6364H59L60.6364 28.1818Z" fill="currentColor" />
    <path d="M117.119 46.5455H98.9375L106.665 0H124.119C128.907 0 132.907 0.962121 136.119 2.88636C139.331 4.81061 141.612 7.56061 142.96 11.1364C144.309 14.7121 144.574 18.9697 143.756 23.9091C142.968 28.6667 141.369 32.7348 138.96 36.1136C136.566 39.4924 133.506 42.0758 129.778 43.8636C126.066 45.6515 121.847 46.5455 117.119 46.5455ZM113.347 35.8182H117.938C120.241 35.8182 122.294 35.4167 124.097 34.6136C125.915 33.7955 127.43 32.4318 128.642 30.5227C129.869 28.5985 130.756 25.9697 131.301 22.6364C131.816 19.4848 131.801 17.053 131.256 15.3409C130.71 13.6136 129.672 12.4167 128.142 11.75C126.612 11.0682 124.634 10.7273 122.21 10.7273H117.528L113.347 35.8182Z" fill="currentColor" />
    <path d="M145.875 46.5455L153.602 0H173.784C177.33 0 180.231 0.477273 182.489 1.43182C184.761 2.38636 186.367 3.73485 187.307 5.47727C188.261 7.2197 188.542 9.27273 188.148 11.6364C187.845 13.3333 187.193 14.8864 186.193 16.2955C185.208 17.6894 183.958 18.8712 182.443 19.8409C180.943 20.7955 179.269 21.4545 177.42 21.8182L177.33 22.2727C179.33 22.3333 181.072 22.8333 182.557 23.7727C184.042 24.697 185.133 25.9848 185.83 27.6364C186.527 29.2727 186.693 31.1818 186.33 33.3636C185.905 35.9091 184.867 38.1742 183.216 40.1591C181.564 42.1439 179.42 43.7045 176.784 44.8409C174.148 45.9773 171.148 46.5455 167.784 46.5455H145.875ZM160.239 36.4545H166.148C168.239 36.4545 169.883 36.053 171.08 35.25C172.292 34.447 173.011 33.2727 173.239 31.7273C173.42 30.6364 173.33 29.7121 172.966 28.9545C172.602 28.197 171.989 27.6212 171.125 27.2273C170.261 26.8333 169.178 26.6364 167.875 26.6364H161.784L160.239 36.4545ZM163.148 18.8182H168.33C169.42 18.8182 170.42 18.6439 171.33 18.2955C172.239 17.947 172.989 17.4394 173.58 16.7727C174.17 16.1061 174.542 15.303 174.693 14.3636C174.92 12.9091 174.572 11.803 173.648 11.0455C172.739 10.2879 171.511 9.90909 169.966 9.90909H164.602L163.148 18.8182Z" fill="currentColor" />
    <path d="M195.898 47.2727C194.125 47.2727 192.678 46.6591 191.557 45.4318C190.451 44.1894 189.989 42.6818 190.17 40.9091C190.352 39.1818 191.133 37.7045 192.511 36.4773C193.905 35.25 195.489 34.6364 197.261 34.6364C198.943 34.6364 200.345 35.25 201.466 36.4773C202.602 37.7045 203.08 39.1818 202.898 40.9091C202.777 42.0909 202.36 43.1667 201.648 44.1364C200.951 45.0909 200.087 45.8561 199.057 46.4318C198.042 46.9924 196.989 47.2727 195.898 47.2727Z" fill="currentColor" />
    <path d="M216.352 0H232.08L238.534 26.3636H239.08L254.261 0H269.989L262.261 46.5455H249.898L254.352 19.6364H253.989L239.261 46.1818H231.807L225.898 19.4545H225.534L220.989 46.5455H208.625L216.352 0Z" fill="currentColor" />
    <path d="M268.25 46.5455L275.977 0H309.523L307.795 10.1818H286.886L285.614 18.1818H304.795L303.068 28.3636H283.886L282.614 36.3636H303.432L301.705 46.5455H268.25Z" fill="currentColor" />
  </svg>
</template>
